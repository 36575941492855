import { DISTRIBUTION_MODES } from 'src/consts';

/**
 * 餐厅网站设置是否可见
 * @param {Object} params
 * @param {Object} params.restaurantInfo 餐厅信息
 * @param {String[]} params.userPermissionCodes 用户权限
 * @param {Object} params.user 用户信息
 * @returns Boolean
 */
export function checkOnlineOrderingSettingVisible({ restaurantInfo }) {
  const { restaurant_contracts } = restaurantInfo;
  // 判断是否开了white_label合约，并且为v2
  return (restaurant_contracts || []).some(
    (item) => item['online_ordering_version'] === 'v2' && item.distribution_mode === DISTRIBUTION_MODES.WHITE_LABEL
  );
}

/**
 *设置打款报表也是否可见
 * @param {Object} params
 * @param {Object} params.restaurantInfo 餐厅信息
 * @param {String[]} params.userPermissionCodes 用户权限
 * @param {Object} params.user 用户信息
 * @returns Boolean
 */
export function checkPayoutReportVisible({ restaurantInfo }) {
  const { restaurant_contracts } = restaurantInfo;
  // 判断pos contract 的cost_plus_start_at 字段是否不为空
  return (
    restaurantInfo.payment_channel_code !== 'adyen' &&
    (restaurant_contracts || []).findIndex(
      (item) => item.distribution_mode === DISTRIBUTION_MODES.POS && !!item.cost_plus_start_at
    ) > -1
  );
}
